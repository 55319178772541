<template>
  <div class="content">
    <div class="content-content">
      <div class="content-content-item" v-for="item in list" :key="item.id">
        <div class="content-content-item-title">{{ $t(item.title) }}</div>
        <div class="content-content-item-child">
          <div
            class="content-content-item-child-item"
            v-for="item1 in item.children"
            :key="item1.id"
          >
            <span>{{ $t(item1.title) }}</span>
            <!--<span>{{ $t(item1.value) }}</span>-->
          </div>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {
      list: [
        {
          id: 1,
          title: "contact.v2",
          children: [{ id: 1001, title: "contact.v3", value: "contact.v11" }],
        },
        {
          id: 2,
          title: "contact.v7",
          children: [{ id: 2001, title: "contact.v8", value: "contact.v13" }],
        },
        {
          id: 3,
          title: "contact.v4",
          children: [
            { id: 3001, title: "contact.v5", value: "contact.v12" },
            { id: 3002, title: "contact.v6", value: "contact.v12" },
          ],
        },
        {
          id: 4,
          title: "contact.v9",
          children: [{ id: 4001, title: "contact.v10", value: "contact.v14" }],
        },
      ],
    };
  },
};
</script>

<style scoped lang="stylus">
.content {
  padding-top: 8%;
  padding-bottom: 12%;
  width: 60%;
  margin: 0 auto;

  &-content {
    margin: 0 0 0 8%;

    &-item {
      width: 50%;
      display: inline-block;
      padding-top: 40px;
      float: left;

      &-title {
        color: #000000;
        font-size: 18px;
        font-weight: 600;
      }

      &-child {
        color: rgb(102, 102, 102);
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
</style>
